<template>
  <div id="users">
    <portal to="filter">
      <el-header class="filter-header">
        <div class="title">User List</div>
        <div class="actions-buttons">
          <el-button type="secondary" class="btn" :class="{ 'active-filter': filterActive ? true : false }" @click="toggle"><i class="icon-filter-filter"></i> Filters</el-button>
          <el-button type="primary" v-if="user.Role == 10 || user.Role==5" class="btn" @click="setFormData(null)">
            <i class="el-icon-loading" style="font-size: 18px; color: white" v-if="loading == 'openAddDialogLoading'"></i>
            <i class="icon-filter-add" v-else></i>Add New User</el-button
          >
        </div>
      </el-header>
    </portal>
    <div class="filter-container" v-if="filter">
      <div class="header">
        <div class="title">Filters</div>
        <el-button type="danger" class="btn" @click="clearFilter">
          <i class="icon-filter-close"></i>
          Clear Filter
        </el-button>
      </div>
      <div class="all-inputs">
        <el-row :gutter="36">
          <el-col :span="8">
            <el-input placeholder="Search" prefix-icon="el-icon-search" v-model="filter.SearchTerm" clearable></el-input>
          </el-col>
        </el-row>
        <el-row :gutter="36">
          <el-col class="input-container" :sm="8">
            <div class="text">Role</div>
            <el-select v-model="filter.Role" clearable @clear="filter.Role = null" placeholder="Select Role">
              <el-option v-for="item in getEnums.find((x) => x.Title == 'TypeOfAuthority').Enums" :key="item.Key" :label="item.DisplayName" :value="item.Key"> </el-option>
            </el-select>
          </el-col>
          <el-col class="input-container" :sm="8">
            <div class="text none">Active or Deactive</div>
            <el-select v-model="filter.GetDeleted" clearable @clear="filter.GetDeleted = false" placeholder="Is Active?">
              <el-option label="All" :value="null"> </el-option>
              <el-option label="Only Actives" :value="false"> </el-option>
              <el-option label="Only Deactives" :value="true"> </el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="table-container">
      <div class="view-info">
        <p>You are viewing page {{ filter.Page }} out of a total of {{ totalCount.PageCount }} pages. (Total Records: {{ totalCount.TotalCount }})</p>
      </div>
      <el-table v-if="userList" :data="userList" style="width: 100%" v-on:sort-change="sortHandler">
        <el-table-column prop="UserNo" label="User ID" width="100" sortable="custom"> </el-table-column>
        <el-table-column prop="Role" label="Authority" width="140">
          <template slot-scope="scope">
            <span>{{ getEnumsDisplay("TypeOfAuthority", scope.row.Role) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="Name & Surname" >
          <template slot-scope="scope">
            <span>{{ scope.row.FirstName }} {{ scope.row.LastName }} </span>
          </template>
        </el-table-column>
        <el-table-column class="none" prop="email" label="Email">
          <template slot-scope="scope">
            <span style="text-transform: none">{{ scope.row.Email }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="contact" label="Contact" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.PhoneNumber }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="Date" sortable="custom">
          <template slot-scope="scope">
            <span>Created Date : {{ $moment(scope.row.CreatedDateTime).format("DD-MM-YYYY") }}</span
            ><br />
            <span>Updated Date : {{ $moment(scope.row.UpdatedDateTime).format("DD-MM-YYYY") }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="IsDeactivated" label="Active or Deactive">
          <template slot-scope="scope">
            <div class="status danger" v-if="scope.row.IsDeactivated == true">
              <span class="danger"> Deactive</span>
            </div>
            <div class="status success" v-if="scope.row.IsDeactivated == false">
              <span class="success"> Active</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="Actions" width="160" v-if="user.Role == 10 ||user.Role==5">
          <template slot-scope="scope">
            <el-dropdown>
              <el-button type="primary"> Actions<i class="el-icon-arrow-down el-icon--right"></i></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <span class="view-edit" @click="setFormData(scope.row.ID)"><i class="icon-pen-to-square-solid"></i> View & Edit</span>
                </el-dropdown-item>
                <el-dropdown-item v-if="scope.row.IsDeactivated == false">
                  <span class="delete-item" @click="toggleUser(scope.row.ID)"><i class="icon-ban-solid"></i>Deactive</span>
                </el-dropdown-item>
                <el-dropdown-item v-if="scope.row.IsDeactivated == true">
                  <span class="confirm-quatation" @click="toggleUser(scope.row.ID)"><i class="icon-check-double-solid"></i>Active</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>

        <template slot="empty">
          <div class="no-data-text">No Records Found.</div>
        </template>
      </el-table>
      <div class="table-bottom">
        <div class="info">
          <span>Show</span>
          <el-select v-model="filter.PageSize">
            <el-option label="12" :value="12"></el-option>
            <el-option label="24" :value="24"></el-option>
            <el-option label="36" :value="36"></el-option>
            <el-option label="100" :value="100"></el-option>
            <el-option label="200" :value="200"></el-option>
          </el-select>
          <span>entries</span>
        </div>
        <el-pagination layout="prev, pager, next" :current-page.sync="filter.Page" :page-size="filter.PageSize" :total="totalCount.TotalCount" v-model="filter.Page"> </el-pagination>
      </div>
    </div>
    <el-dialog top="5vh" :visible.sync="dialogVisibleUsers" :show-close="false" :destroy-on-close="true" :close-on-click-modal="false">
      <addOrUpdateUsersDialog :id="setForm" @close="closeAddDialog()" v-if="dialogVisibleUsers" />
    </el-dialog>
  </div>
</template>

<script>
import addOrUpdateUsersDialog from "../../components/addOrUpdateUsersDialog.vue";
export default {
  components: { addOrUpdateUsersDialog },
  data() {
    return {
      setForm: null,
      loading:false,
      timeout:null,
      dialogVisibleUsers: false,
      filterActive: false,
    };
  },
  beforeMount() {
    this.$store.dispatch("getUserList");
    console.log(this.user);
  },
  mounted() {
    this.jQuery(".filter-container").hide();
    this.filter.GetDeleted = null;
  },
  computed: {
    getEnums() {
      return this.$store.getters.getEnums;
    },
    userList() {
      return this.$store.getters.getUserList;
    },
    filter() {
      return this.$store.getters.getUsersFilter;
    },
    totalCount() {
      return this.$store.getters.getUsersTotalCount;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
        async sortHandler(params) {
      // params objesi: {columns, prop, order}
      console.log(params);
      this.filter.SortBy = params.prop;
      this.filter.SortDirection = params.order == "descending" ? 1 : 0;
    },
    setFormData(id) {
      if (id != null) {
        this.loading = "openAddDialogLoading" + id;
      } else {
        this.loading = "openAddDialogLoading";
      }
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.setForm = id;
        this.dialogVisibleUsers = true;
        this.loading = false;
      }, 500);
    },
    toggle() {
      this.filterActive = !this.filterActive;
      this.jQuery(".filter-container").slideToggle();
    },
    async toggleUser(Id) {
      var payload = {
        ID: Id,
      };

      this.$confirm("Are you sure active or deactive this user?", "Warning", {
        confirmButtonText: "Yes",
        confirmButtonClass: "danger-btn-confirm",
        cancelButtonText: "No",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$client.post("/User/DeactivateUserToggle", payload);
          if (res.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
            await this.$store.dispatch("getUserList");
          }
        })
        .catch(() => {});
    },
    closeAddDialog(id) {
      this.setForm = id;
      this.dialogVisibleUsers = false;
    },
    clearFilter() {
      this.filter.Role = null;
      this.filter.SearchTerm = null;
      this.filter.GetDeleted = null;
    },
  },
  watch: {
    filter: {
      deep: true,
      handler: async function () {
        await this.$store.dispatch("getUserList");
      },
    },
  },
};
</script>

<style></style>
