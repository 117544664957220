<template>
  <div class="dialog-container">
    <transition name="el-fade-in" mode="out-in">
      <el-row style="display: flex; align-items: center; justify-content: center; width: 100%" v-if="loading == 'updateScreenLoading' && form == null" key="screen-loading">
        <lottie-player autoplay loop mode="normal" src="/lottie/packaging-source-loading-lottie.json" style="width: 320px"></lottie-player>
      </el-row>
      <el-row v-else key="content-loading">
        <div class="dialog-title">
          <div class="head-title">{{ form.ID != null ? "User" : "New User" }}<span class="yellow-dot">.</span>{{ form.ID != null ? "  #" + form.UserNo : "" }}</div>
          <el-button class="btn close" type="danger" @click="close()"> <i class="icon-xmark-solid"></i></el-button>
        </div>
        <div class="form">
          <el-row :gutter="24">
            <div class="title">Authority</div>
            <el-col class="input-container" :sm="8">
              <div class="text">User Type</div>
              <el-select v-model="form.Role" placeholder="Select" class="select" v-if="getEnums" clearable @clear="clearRole">
                <el-option v-for="item in getEnums.find((x) => x.Title == 'TypeOfAuthority').Enums" :key="item.Key" :label="item.DisplayName" :value="item.Key"> </el-option>
              </el-select>
              <span class="error-validation">{{ $errorMessage("Role", validation) }}</span>
            </el-col>
            <el-col class="input-container" :sm="8">
              <div class="text">Job Title</div>
              <el-input v-model="form.JobTitle" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("JobTitle", validation) }}</span>
            </el-col>
            <el-col class="input-container" :sm="8" v-if="form.Role == 4">
              <div class="text">Warehouse List</div>
              <el-select v-model="form.WarehouseId" placeholder="Select" class="select" clearable>
                <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.Title" :value="item.ID"> </el-option>
              </el-select>
            </el-col>
          </el-row>
          <div class="line"></div>
          <el-row :gutter="24">
            <el-col class="input-container" :sm="8">
              <div class="text">First Name</div>
              <el-input v-model="form.FirstName" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("FirstName", validation) }}</span>
            </el-col>
            <el-col class="input-container" :sm="8">
              <div class="text">Last Name</div>
              <el-input v-model="form.LastName" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("LastName", validation) }}</span>
            </el-col>
            <el-col class="input-container email" :sm="8">
              <div class="text email">Email</div>
              <el-input v-model="form.Email" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("Email", validation) }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col class="input-container" :sm="8">
              <div class="text">Contact Number</div>
              <el-input v-model="form.PhoneNumber" placeholder="07776668899"></el-input>
              <span class="error-validation">{{ $errorMessage("PhoneNumber", validation) }}</span>
            </el-col>
          </el-row>
          <div class="line"></div>
          <el-row :gutter="24">
            <el-col class="input-container password" :sm="8">
              <div class="text">Password</div>
              <el-input placeholder="Please input password" v-model="form.Password" show-password></el-input>
              <span class="error-validation">{{ $errorMessage("Password", validation) }}</span>
            </el-col>
            <el-col :sm="4" class="input-container" style="height: 67px; justify-content: flex-end">
              <el-button @click="generateRandomPassword"><i class="icon-arrows-rotate-solid"></i> Auto Generate</el-button>
            </el-col>
          </el-row>
          <div class="line"></div>
        </div>
        <div class="dialog-footer footerbutton">
          <el-button class="btn" type="danger" @click="close()"> <i class="icon-xmark-solid"></i> Cancel</el-button>
          <el-button class="btn" type="primary" @click="addOrUpdate" :loading="loading == 'saveLoading'" :icon="form.ID != null ? 'el-icon-edit' : 'el-icon-plus'">{{ form.ID != null ? "Update User" : "Add New User" }}</el-button>
        </div>
      </el-row>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      loading: false,
      form: null,
      timeout: null,
    };
  },

  methods: {
    close(id) {
      this.$emit("close", id);
    },
    async addOrUpdate() {
      this.$store.commit("setUserValidation", []);
      if (this.validation.length > 0) {
        return;
      }

      this.loading = "saveLoading";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var res = await this.$store.dispatch("AddUpdateUser", this.form);
        if (!res.HasError) {
          this.close();
        }
        this.loading = false;
      }, 500);
    },
    generateRandomPassword() {
      var randomstring = Math.random().toString(36).slice(-8);
      this.form.Password = randomstring;
    },
    clearRole() {
      this.form.Role = null;
    },
  },
  async created() {
    this.loading = "updateScreenLoading";
    if (this.timeout != null) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      if (this.id != null && this.id != undefined) {
        var res = await this.$store.dispatch("getOneUser", this.id);
        if (!res.data.HasError) {
          this.form = res.data.Data;
          await this.$store.dispatch("getWarehouseList");
        } else {
          this.close();
        }
      } else {
        this.form = {
          Email: null,
          Password: null,
          JobTitle: null,
          FirstName: null,
          PhoneNumber: null,
          LastName: null,
          Role: null,
          WarehouseId: null,
        };
        await this.$store.dispatch("getWarehouseList");
      }
      this.loading = false;
    }, 500);
  },
  computed: {
    getEnums() {
      return this.$store.getters.getEnums;
    },
    validation() {
      return this.$store.getters.getUsersValidation;
    },
    warehouseList() {
      return this.$store.getters.getWarehouseList;
    },
  },
};
</script>

<style></style>
